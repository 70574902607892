<template>
  <v-container v-if="all_loaded" id="users-table" fluid tag="section">
    <base-material-card
      icon="mdi-account"
      inline
      color="secondary"
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h4">
          <b>{{ $tc("user", 2) }}</b>
        </h1></template
      >
      <InvestorsTable :Users="users" />
    </base-material-card>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import InvestorsTable from "@/views/pages/dashboard/InvestorsTable";
import moment from "moment";

export default {
  name: "UsersTable",
  components: {
    InvestorsTable,
  },
  data: () => ({
    apiService: new ApiService(),
    all_loaded: false,
    users: [],
  }),
  async created() {
    this.apiService
      .getRequest(`banker/users`)
      .then((resp) => {
        this.users = resp.content;
        this.all_loaded = true;
      })
      .catch((error) => {
        this.all_loaded = true;
      });
  },
  methods: {},
};
</script>
